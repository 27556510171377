import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Modal,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import { Delete, Edit } from "@material-ui/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../../../context/constant";

export default function DepositTable(props) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState("");
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const showMoreList = () => {};

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const openList = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleCloseList = () => {
    setAnchorEl(null);
  };

  const updateDepositDetails = () => {
    let statusObj = "";
    if (selectedIndex === 0) {
      statusObj = "Pending";
    } else if (selectedIndex === 1) {
      statusObj = "Approved";
    } else if (selectedIndex === 2) {
      statusObj = "Reject";
    }
    axios
      .put(
        `${API_URL}/deposit/update/${selectedTransactionId}`,
        { status: statusObj },
        config
      )
      .then(() => {
        handleClose();
        props.reloadData();
        toast.success("Successfully updated transaction!");
      })
      .catch(() => {
        handleClose();
        toast.error("Error while updating transaction!");
      });
  };

  const closeTwoComp = () => {
    handleOpen();
    setMoreMenuOpen(false);
  };

  const deleteTransaction = (transactionId) => {
    axios
      .delete(`${API_URL}/deposit/delete/${transactionId}`, config)
      .then((res) => {
        toast.success("Successfully Deleted Deposit Transaction!");
        props.reloadData();
      })
      .catch(() => {
        toast.error("Error Deleting Deposit Transaction!");
      });
  };

  const openTransaction = (id) => {
    window.open(`https://tronscan.org/#/transaction/${id}`, "_blank");
  };

  const options = ["Pending", "Approved", "Reject"];
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Update Status
          </Typography>
          <List
            component="nav"
            aria-label="Device settings"
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItem
              button
              id="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-label="Update deposit status"
              aria-expanded={openList ? "true" : undefined}
              onClick={handleClickListItem}
            >
              <ListItemText
                primary="Update deposit status"
                secondary={options[selectedIndex]}
              />
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={openList}
            onClose={handleCloseList}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>

          <div style={{ width: "100%", textAlign: "end", marginTop: 10 }}>
            <Button
              size="medium"
              color="primary"
              onClick={() => updateDepositDetails()}
              variant="contained"
              style={{ marginRight: 10 }}
            >
              Submit
            </Button>
            <Button
              size="medium"
              onClick={() => handleClose()}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      <Table className="mb-0">
        <TableHead>
          <TableRow>
            <TableCell key="1">Transaction Id</TableCell>
            <TableCell key="3">Amount</TableCell>
            <TableCell key="2">Email</TableCell>

            <TableCell key="5">Status</TableCell>
            <TableCell key="9">Remark</TableCell>
            <TableCell key="10">Method</TableCell>
            <TableCell key="6">Edit</TableCell>
            <TableCell key="7">Delete</TableCell>
            <TableCell key="8">Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map(
            ({ _id, transactionId, amount, user, status, remark, method }) => (
              <TableRow key={transactionId}>
                <TableCell
                  className="pl-3 fw-normal"
                  style={{ maxWidth: "300px", overflowWrap: "break-word" }}
                  onClick={() => openTransaction(transactionId)}
                >
                  {transactionId}
                </TableCell>
                <TableCell>
                  {method === "IMPS" ? "₹" : "$"}
                  {method === "IMPS"
                    ? (amount * 89).toFixed(2)
                    : amount.toFixed(4)}
                </TableCell>
                <TableCell>{user.emailId}</TableCell>
                <TableCell>{status}</TableCell>
                <TableCell style={{ minWidth: "250px" }}>{remark}</TableCell>
                <TableCell>{method}</TableCell>
                <TableCell
                  onClick={() => {
                    props.openEditModal({
                      _id,
                      transactionId,
                      amount,
                      emailId: user.emailId,
                      status,
                      remark,
                    });
                  }}
                >
                  <Edit />
                </TableCell>
                <TableCell
                  onClick={() => {
                    deleteTransaction(_id);
                  }}
                >
                  <Delete />
                </TableCell>
                <TableCell onClick={() => showMoreList()}>
                  <IconButton
                    color="primary"
                    aria-owns="widget-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      setSelectedTransactionId(_id);
                      setMoreMenuOpen(true);
                    }}
                    ref={setMoreButtonRef}
                  >
                    <MoreIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography>View</Typography>
        </MenuItem>
        <MenuItem onClick={() => closeTwoComp()}>
          <Typography>Status</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
