/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Add } from "@material-ui/icons";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { API_URL } from "../../context/constant";
import { Sort } from "@material-ui/icons";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "material-ui-search-bar";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  loader: {
    background: "transparent",
  },
}));

export default function Tables(props) {
  const addUserValidation = yup.object().shape({
    emailId: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    fName: yup.string().required("First Name is Required"),
    lName: yup.string().required("Last Name is Required"),
    phone: yup.string().required("Phone is Required"),
    transactionAddress: yup.string(),
    nameInBank: yup.string(),
    bankAccountNo: yup.number(),
    bankIFSC: yup.string(),
    deleted: yup.boolean(),
    transactionAddressUpdate: yup.boolean(),
    availableBalance: yup.number().required("Amount is Required"),
    password: yup
      .string()
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
  });

  const editUserValidation = yup.object().shape({
    emailId: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    fName: yup.string().required("First Name is Required"),
    lName: yup.string().required("Last Name is Required"),
    phone: yup.string().required("Phone is Required"),
    transactionAddress: yup.string(),
    deleted: yup.boolean(),
    transactionAddressUpdate: yup.boolean(),
    availableBalance: yup.number().required("Amount is Required"),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editFlow, setEditFlow] = useState(false);
  const [initialValue, setInitialValue] = useState({
    emailId: "",
    password: "",
    fName: "",
    lName: "",
    phone: "",
    transactionAddressUpdate: false,
    impsAddressUpdate: false,
    transactionAddress: "",
    bankAccountNo: "",
    bankIFSC: "",
    nameInBank: "",
    availableBalance: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searched, setSearched] = useState("");
  const [userArr, setUserArr] = useState([]);
  const [actualData, setActualData] = useState([]);
  const [isSortData, setIsSortData] = useState(false);
  const [userValidation, setUserValidation] = useState(addUserValidation);

  useEffect(() => {
    getUserInfo(isSortData);
  }, []);

  const requestSearch = (searchedVal) => {
    const filteredRows = actualData.filter((row) => {
      return row.emailId.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setUserArr(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const getUserInfo = (isSortData) => {
    if (isSortData === true) {
      axios
        .post(`${API_URL}/userMaster/get`, { sort: { macAddress: -1 } })
        .then((res) => {
          if (res.data) {
            setUserArr(res.data.data.data);
            setActualData(res.data.data.data);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .post(`${API_URL}/userMaster/get`, null)
        .then((res) => {
          if (res.data) {
            setUserArr(res.data.data.data);
            setActualData(res.data.data.data);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  const handleClick = () => {
    setUserValidation(addUserValidation);
    setInitialValue({
      emailId: "",
      password: "",
      fName: "",
      lName: "",
      phone: "",
      availableBalance: "",
      transactionAddressUpdate: false,
      impsAddressUpdate: false,
      transactionAddress: "",
      bankAccountNo: "",
      bankIFSC: "",
      nameInBank: "",
    });
    setEditFlow(false);
    handleOpen();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    overflow: "scroll",
    boxShadow: 24,
    p: 4,
  };

  const handleCreateUser = async (values) => {
    const impsAddress = {
      nameInBank: values.nameInBank,
      bankIFSC: values.bankIFSC,
      bankAccountNo: values.bankAccountNo,
    };
    const { nameInBank, bankAccountNo, bankIFSC, ...formattedValues } = values;
    formattedValues.impsAddress = impsAddress;
    if (!editFlow) {
      await addUserToDb({
        ...formattedValues,
        confirmPassword: values.password,
      });
      handleClose();
    } else {
      const { availableBalance, password,  ...data } = formattedValues;
      updateUserToDB(data);
      handleClose();
    }
  };

  const updateUserToDB = (userDetails) => {
    axios
      .put(`${API_URL}/userMaster/update/${userDetails._id}`, userDetails)
      .then(() => {
        getUserInfo(isSortData);
        handleClose();
        toast.success("User updated Successfully!");
      })
      .catch(() => {
        toast.error("Error updating user profile!");
      });
  };

  const handleCallback = () => {
    getUserInfo(isSortData);
  };

  const addUserToDb = async (userData) => {
    axios
      .post(`${API_URL}/userMaster/add`, userData)
      .then(async (res) => {
        if (res.data.status === "OK") {
          getUserInfo(isSortData);
          toast.success("User Added Successfully!");
        } else {
          toast.error("Error while adding user!");
        }
        handleClose();
      })
      .catch(async (err) => {
        toast.error("Error while adding user!");
      });
  };

  const parentOpenEditModal = (userDetails) => {
    setUserValidation(editUserValidation);
    console.log(userDetails);
    if (userDetails.impsAddress) {
      userDetails.bankAccountNo = userDetails.impsAddress.bankAccountNo;
      userDetails.bankIFSC = userDetails.impsAddress.bankIFSC;
      userDetails.nameInBank = userDetails.impsAddress.nameInBank;
    } else {
    }
    setInitialValue((prev) => {
      return { ...prev, ...userDetails };
    });
    setEditFlow(true);
    setOpen(true);
  };

  const macSortData = () => {
    setIsSortData(!isSortData);
    getUserInfo(!isSortData);
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Formik
              enableReinitialize
              validationSchema={userValidation}
              initialValues={initialValue}
              onSubmit={(values) => {
                handleCreateUser(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                dirty,
              }) => (
                <>
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      style={{ marginBottom: 20 }}
                      variant="h3"
                      component="h2"
                    >
                      {editFlow ? "Edit" : "Add"} User
                      {console.log(values)}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={editFlow ? true : false}
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.emailId}
                      onChange={handleChange("emailId")}
                      label="Email"
                      type="email"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.fName}
                      onChange={handleChange("fName")}
                      label="First Name"
                      type="text"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.lName}
                      onChange={handleChange("lName")}
                      label="Last Name"
                      type="text"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      disabled={editFlow ? true : false}
                      variant="outlined"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      label="Phone"
                      type="tel"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.availableBalance}
                      onChange={handleChange("availableBalance")}
                      label="Amount"
                      type="number"
                      disabled={editFlow}
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.transactionAddress}
                      onChange={handleChange("transactionAddress")}
                      label="Transactional Address"
                      type="text"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.bankAccountNo}
                      onChange={handleChange("bankAccountNo")}
                      label="Bank Account Number"
                      type="number"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.bankIFSC}
                      onChange={handleChange("bankIFSC")}
                      label="IFSC Code"
                      type="text"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.nameInBank}
                      onChange={handleChange("nameInBank")}
                      label="IFSC Code"
                      type="text"
                    />

                    {!editFlow && (
                      <TextField
                        fullWidth
                        style={{ marginBottom: 14 }}
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange("password")}
                        label="Enter Password"
                        type="password"
                      />
                    )}
                    {editFlow && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.deleted}
                            onChange={handleChange("deleted")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Ban"
                      />
                    )}

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.transactionAddressUpdate}
                          onChange={handleChange("transactionAddressUpdate")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Transaction Address Updated"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.impsAddressUpdate}
                          onChange={handleChange("impsAddressUpdate")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="IMPS Address Updated"
                    />
                    <div style={{ width: "100%", textAlign: "end" }}>
                      {!editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Save
                        </Button>
                      )}

                      {editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        size="medium"
                        onClick={() => handleClose()}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </Modal>
        <PageTitle
          title="Users"
          button={
            <>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={(e) => handleClick(e)}
              >
                <Add />
                Add User
              </Button>
            </>
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <SearchBar
              style={{ marginBottom: 10 }}
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
          </Grid>
          <Grid item xs={2}>
            <Sort
              sx={{ fontSize: 40 }}
              fontSize="large"
              onClick={macSortData}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {actualData && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={userArr.length}
                title="User List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <Table
                  {...props}
                  data={userArr}
                  parentOpenModal={parentOpenEditModal}
                  parentCallback={handleCallback}
                />
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay>
    </>
  );
}
