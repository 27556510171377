/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { Delete, Edit } from "@material-ui/icons";
import { API_URL } from "../../context/constant";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Modal,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Formik } from "formik";
import * as yup from "yup";

// data
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "material-ui-search-bar";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default function AnnouncementsTables() {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const [isLoading, setIsLoading] = useState(true);

  const [editFlow, setEditFlow] = useState(false);
  const [initialValue, setInitialValue] = useState({
    Title: "",
    Body: "",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searched, setSearched] = useState("");
  const [actualData, setActualData] = useState([]);
  const [userArr, setUserArr] = useState([]);
  const [pressed, setPressed] = useState(false)
  useEffect(() => {
    getUserInfo();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const addAnnouncementValidation = yup.object().shape({
    Title: yup.string().required("Title is Required"),
    Body: yup.string().required("Body is Required"),
  });

  const getUserInfo = () => {
    axios
      .post(`${API_URL}/announcements/get`, null, config)
      .then((res) => {
        if (res.data) {
          setUserArr(res.data.data.data);
          setActualData(res.data.data.data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = actualData.filter((row) => {
      return (
        row.Title.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.Body.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setUserArr(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleClick = () => {
    setInitialValue({ Title: "", Body: "" });
    setEditFlow(false);
    handleOpen();
  };

  const handleCreateAnnouncement = (values) => {
    if (!editFlow) {
      addAnnouncementsToDb(values);
    } else {
      updateAnnouncementsToDB(values);
    }
  };

  const updateAnnouncementsToDB = (announcements) => {
    setPressed(true);
    axios
      .put(
        `${API_URL}/announcements/update/${announcements._id}`,
        announcements,
        config
      )
      .then(() => {
        getUserInfo();
        handleClose();
        toast.success("Announcements updated Successfully!");
      })
      .catch(() => {
        toast.error("Error updating Announcement!");
      });
  };

  const addAnnouncementsToDb = async (announcements) => {
    axios
      .post(`${API_URL}/announcements/add`, announcements)
      .then(async (res) => {
        if (res.data.status === "OK") {
          getUserInfo();
          toast.success("Announcement Added Successfully!");
        } else {
          toast.error("Error while adding Announcement!");
        }
        handleClose();
      })
      .catch(async (err) => {
        toast.error("Error while adding Announcement!");
      });
  };

  const editAnnouncement = (announcements) => {
    setPressed(false);
    setInitialValue(announcements);
    setEditFlow(true);
    setOpen(true);
  };

  const deleteAnnouncement = (announcement) => {
    axios
      .delete(`${API_URL}/announcements/delete/${announcement._id}`, config)
      .then((res) => {
        toast.success("Announcement deleted succesfully!");
        getUserInfo();
      })
      .catch(() => {
        toast.error("Unable to delete the Announcement!");
      });
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Formik
              enableReinitialize
              validationSchema={addAnnouncementValidation}
              initialValues={initialValue}
              onSubmit={(values) => {
                handleCreateAnnouncement(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                dirty,
              }) => (
                <>
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      style={{ marginBottom: 20 }}
                      variant="h3"
                      component="h2"
                    >
                      Add Announcement
                    </Typography>
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.Title}
                      onChange={handleChange("Title")}
                      label="Title"
                      type="text"
                    />
                    <TextField
                      multiline
                      minRows={4}
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.Body}
                      onChange={handleChange("Body")}
                      label="Body"
                      type="text"
                    />

                    <div style={{ width: "100%", textAlign: "end" }}>
                      {!editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Save
                        </Button>
                      )}

                      {editFlow && (
                        <Button
                          disabled={pressed}
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        size="medium"
                        onClick={() => handleClose()}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </Modal>
        <PageTitle
          title="Notices"
          button={
            <>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={(e) => handleClick(e)}
              >
                <Add />
                Add New
              </Button>
            </>
          }
        />
        <SearchBar
          style={{ marginBottom: 10 }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <Grid container spacing={4}>
          {actualData && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={userArr.length}
                title="Announcements List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <Table className="mb-0">
                  <TableHead>
                    <TableRow>
                      <TableCell key="1">Title</TableCell>
                      <TableCell key="2">Body</TableCell>
                      <TableCell key="3">AddedAt</TableCell>
                      <TableCell key="4">Edit</TableCell>
                      <TableCell key="5">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userArr.map(({ _id, Title, Body, addedAt }) => (
                      <TableRow key={_id}>
                        <TableCell className="pl-3 fw-normal">
                          {Title}
                        </TableCell>
                        <TableCell>{Body}</TableCell>
                        <TableCell>{addedAt}</TableCell>
                        <TableCell
                          onClick={() => {
                            editAnnouncement({
                              _id,
                              Title,
                              Body,
                              addedAt,
                            });
                          }}
                        >
                          <Edit />
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            deleteAnnouncement({
                              _id,
                              Title,
                              Body,
                              addedAt,
                            });
                          }}
                        >
                          <Delete />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay>
    </>
  );
}
