/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Card,
  CardContent,
  Box,
  Button,
  Modal,
  TextField,
} from "@material-ui/core";

import * as yup from "yup";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import axios from "axios";
import { API_URL } from "../../context/constant";
import { Formik } from "formik";
import { toast } from "react-toastify";

export default function Dashboard(props) {
  var classes = useStyles();
  const [withdraw, setWithdraw] = useState({});
  const [deposit, setDeposit] = useState({});
  const [userCount, setUserCount] = useState();
  const [maintenance, setMaintenance] = useState(false);
  const [open, setOpen] = useState(false);
  const [initialValue, setInitialValue] = useState({
    signingBonus: "",
    userCountLimit: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleClick = () => {
    handleOpen();
  };

  const addUserValidation = yup.object().shape({
    signingBonus: yup.string().required("Signing Bonus is Required"),
    userCountLimit: yup.string().required("User Count Limit is Required"),
  });

  const handleSigningBonusChange = async (values) => {
    const { signingBonus, userCountLimit } = values;
    try {
      await axios.put(
        `${API_URL}/userMaster/updateSigningBonus`,
        {
          signingBonus: signingBonus,
          userLimit: userCountLimit,
        },
        config
      );
      handleClose();
      setInitialValue({
        signingBonus: signingBonus,
        userCountLimit: userCountLimit,
      });
      toast.success("Singing Bonus updated successfully");
    } catch (err) {
      console.log(err);
      toast.error("Error updating Signing Bonus!");
    }
  };

  useEffect(() => {
    const getData = async () => {
      const withdrawData = await axios.post(
        `${API_URL}/withdraw/getWithdrawStats`,
        {},
        config
      );
      const depositData = await axios.post(
        `${API_URL}/deposit/getDepositStats`,
        {},
        config
      );
      const { data } = await axios.get(`${API_URL}/userMaster/getUserCount`);
      const maintenanceData = await axios.get(`${API_URL}/site/maintenance`);
      // console.log(maintenanceData);
      setMaintenance(maintenanceData.data.maintenance);
      setUserCount(data.data.userCount);
      setInitialValue({
        signingBonus: data.data.signingBonus,
        userCountLimit: data.data.userLimit,
      });
      setWithdraw(withdrawData.data.data);
      setDeposit(depositData.data.data);
    };
    getData();
  }, []);

  const handlemaintenanceClick = async () => {
    try {
      const maintenanceData = await axios.put(`${API_URL}/site/maintenance`, {
        maintenance: !maintenance,
      });
      setMaintenance(maintenanceData.data.maintenance);
      toast.success(
        `maintenance status changed to ${maintenanceData.data.maintenance ? "on" : "off"
        }`
      );
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>


          <Formik
            enableReinitialize
            validationSchema={addUserValidation}
            initialValues={initialValue}
            onSubmit={(values) => {
              handleSigningBonusChange(values);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              dirty,
            }) => (
              <>
                <Box sx={style} >
                  <Typography
                    id="modal-modal-title"
                    style={{ marginBottom: 20 }}
                    variant="h3"
                    component="h2"
                  >
                    Signing Bonus
                  </Typography>

                  <TextField
                    fullWidth
                    style={{ marginBottom: 14 }}
                    variant="outlined"
                    value={values.signingBonus}
                    onChange={handleChange("signingBonus")}
                    label="Signing Bonus"
                    type="number"
                  />

                  <TextField
                    fullWidth
                    style={{ marginBottom: 14 }}
                    variant="outlined"
                    value={values.userCountLimit}
                    onChange={handleChange("userCountLimit")}
                    label="User Count Limit"
                    type="number"
                  />
                  <div style={{ width: "100%", textAlign: "end" }}>
                    <Button
                      size="medium"
                      color="primary"
                      onClick={handleSubmit}
                      variant="contained"
                      style={{ marginRight: 10 }}
                    >
                      Save
                    </Button>
                    <Button
                      size="medium"
                      onClick={() => handleClose()}
                      color="primary"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </div>
                </Box>
              </>
            )}
          </Formik>
        </div>
      </Modal>
      <PageTitle
        title="Dashboard"
        button={
          <>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={(e) => handleClick(e)}
            >
              Sign Bonus
            </Button>
          </>
        }
      />
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", margin: "auto", gridGap: "10px" }}>
        <Button
          variant="contained"
          size="medium"
          onClick={handlemaintenanceClick}
        >
          <span style={{ textAlign: "center" }}>
            maintenance is <span style={{ fontWeight: 800, color: "#536DFE" }}> {maintenance ? "on" : "off"}</span>
          </span>
        </Button>

      </Box>

      <div className={classes.dashboardCardsContainer}>
        <div className={classes.dashboardCard} >
          <Card style={{ backgroundColor: "lightgreen" }}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                  variant="h4"
                  component="div"
                  style={{ marginBottom: "1rem" }}
                >
                  ${parseFloat(deposit.todayDeposit).toFixed(4)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h6">Today's Deposit</Typography>
              </Box>
            </CardContent>
          </Card>
        </div>
        <div className={classes.dashboardCard} >
          <Card style={{ backgroundColor: "peachpuff" }}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                  variant="h4"
                  component="div"
                  style={{ marginBottom: "1rem" }}
                >
                  ${parseFloat(withdraw.todayWithdrawal).toFixed(4)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Today's Withdrawal
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </div>

        <div className={classes.dashboardCard} >
          <Card style={{ backgroundColor: "lightgreen" }}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                  variant="h4"
                  component="div"
                  style={{ marginBottom: "1rem" }}
                >
                  ${parseFloat(deposit.totalDeposit).toFixed(4)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Total Deposit
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </div>
        <div className={classes.dashboardCard} >
          <Card style={{ backgroundColor: "peachpuff" }}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                  variant="h4"
                  component="div"
                  style={{ marginBottom: "1rem" }}
                >

                  ${parseFloat(withdraw.totalWithdrawal).toFixed(4)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Total Withdrawal
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </div>

        <div style={{ margin: "2rem", width: "100%" }}>
          <Card style={{ backgroundColor: "lavender" }}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                  variant="h4"
                  component="div"
                  style={{ marginBottom: "1rem" }}
                >
                  {userCount}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Total User Count
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
