import React, { useEffect, useState } from "react";
import {
  Grid,
  Modal,
  Typography,
  TextField,
  Box,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import DepositTable from "../dashboard/components/DepositTable/DepositTable";

import { Formik } from "formik";
import * as yup from "yup";
// data
import axios from "axios";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { API_URL } from "../../context/constant";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default function Deposits(props) {
  const [pressed, setPressed] = useState(false)
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [editFlow, setEditFlow] = React.useState(true);
  const [initialValue, setInitialValue] = useState({
    amount: "",
    emailId: "",
    status: "",
    remark: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userArr, setUserArr] = useState([]);
  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    axios
      .post(`${API_URL}/deposit/get`, null)
      .then((res) => {
        // console.log("deposite>>>", res.data.data.data);
        if (res.data) {
          setUserArr(res.data.data.data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const depositValidation = yup.object().shape({
    emailId: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    amount: yup.string().required("Amount is Required"),
    status: yup.string().required("Status is Required"),
    remark: yup.string().required("Remark is Required"),
  });

  const openEditModal = (values) => {
    // console.log("Edit Values", values);
    setInitialValue(values);
    setPressed(false);
    setEditFlow(true);
    handleOpen(true);
  };

  const handleAddDeposit = (values) => {
    // console.log(values);
    setPressed(true);
    if (editFlow) {
      axios
        .put(
          `${API_URL}/deposit/approve/${values._id}`,
          { status: values?.status, remark: values?.remark },
          config
        )
        .then((res) => {
          console.log("Success");
          getUserInfo();
          handleClose();
          toast.success("Deposit edit Successfully!");
        })
        .catch((err) => {
          console.log("error", err);
          toast.error("Error while editing Deposit!");
        });
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Formik
              enableReinitialize
              validationSchema={depositValidation}
              initialValues={initialValue}
              onSubmit={(values) => {
                handleAddDeposit(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                dirty,
              }) => (
                <>
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      style={{ marginBottom: 20 }}
                      variant="h3"
                      component="h2"
                    >
                      Edit Deposit
                    </Typography>
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      disabled={true}
                      value={values.emailId}
                      onChange={handleChange("emailId")}
                      label="Email"
                      type="email"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      disabled={true}
                      value={parseFloat(values.amount).toFixed(4)}
                      onChange={handleChange("amount")}
                      label="Amount"
                      type="number"
                    />

                    <FormControl
                      style={{ marginBottom: 14 }}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Status type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.status}
                        onChange={(e) => {
                          // console.log("details status", e.target.value);
                          handleChange("status")(e.target.value);
                        }}
                        label="Status"
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"Reject"}>Reject</MenuItem>
                        <MenuItem value={"Approved"}>Approved</MenuItem>
                      </Select>
                      <TextField
                        fullWidth
                        style={{ marginTop: 14 }}
                        variant="outlined"
                        value={values.remark}
                        onChange={handleChange("remark")}
                        label="Remark"
                        type="string"
                      />
                    </FormControl>

                    {!editFlow && (
                      <TextField
                        fullWidth
                        style={{ marginBottom: 14 }}
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange("password")}
                        label="Enter Password"
                        type="password"
                      />
                    )}
                    <div style={{ width: "100%", textAlign: "end" }}>
                      {!editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Save
                        </Button>
                      )}

                      {editFlow && (
                        <Button
                          disabled={pressed}
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        size="medium"
                        onClick={() => handleClose()}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </Modal>
        <PageTitle title="Deposits" />
        <Grid container spacing={4}>
          {userArr && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={userArr.length}
                title="Deposit List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <DepositTable
                  {...props}
                  openEditModal={openEditModal}
                  reloadData={getUserInfo}
                  data={userArr}
                />
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay>
    </>
  );
}
