/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { API_URL } from "../../context/constant";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Add } from "@material-ui/icons";

// data
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default function SettingsPage(props) {
  const [open, setOpen] = React.useState(false);
  const [editFlow, setEditFlow] = React.useState(false);
  const [initialValue, setInitialValue] = useState({
    emailId: "",
    password: "",
    fName: "",
    lName: "",
    phone: "",
    userType: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userArr, setUserArr] = useState([]);
  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    axios.post(`${API_URL}/userMaster/get`, null).then((res) => {
      // console.log(res.data.data.data);
      let users = [];
      if (res.data) {
        res.data.data.data.forEach((element) => {
          // console.log(element);
          if (
            element.userType.includes("admin") ||
            element.userType.includes("super_user") ||
            element.userType.includes("sales")
          ) {
            users.push(element);
          }
        });
        setUserArr(users);
      }
    });
  };

  const addUserValidation = yup.object().shape({
    emailId: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    fName: yup.string().required("First Name is Required"),
    lName: yup.string().required("Last Name is Required"),
    phone: yup.string().required("Phone is Required"),
    password: yup
      .string()
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
    userType: yup.string().required("User Type is Required"),
  });

  const handleClick = () => {
    handleOpen();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleCreateUser = (values) => {
    // console.log(values);
    addUserToDb({
      ...values,
      confirmPassword: values.password,
      referalBy: "XGXH6250",
    });
  };

  const addUserToDb = async (userData) => {
    axios
      .post(`${API_URL}/userMaster/add`, userData)
      .then(async (res) => {
        // console.log(res.data.data.data);
        if (res.data.status === "OK") {
          getUserInfo();
          await toast.success("User Added Successfully!");
        } else {
          await toast.error("Error while adding user!");
        }
        handleClose();
      })
      .catch(async (err) => {
        await toast.error("Error while adding user!");
      });
  };

  const classes = useStyles();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>

        <Formik
          enableReinitialize
          validationSchema={addUserValidation}
          initialValues={initialValue}
          onSubmit={(values) => {
            handleCreateUser(values);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            isValid,
            dirty,
          }) => (
            <>
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  style={{ marginBottom: 20 }}
                  variant="h3"
                  component="h2"
                >
                  Add User
                </Typography>
                <TextField
                  fullWidth
                  style={{ marginBottom: 14 }}
                  variant="outlined"
                  value={values.emailId}
                  onChange={handleChange("emailId")}
                  label="Email"
                  type="email"
                />
                <TextField
                  fullWidth
                  style={{ marginBottom: 14 }}
                  variant="outlined"
                  value={values.fName}
                  onChange={handleChange("fName")}
                  label="First Name"
                  type="text"
                />
                <TextField
                  fullWidth
                  style={{ marginBottom: 14 }}
                  variant="outlined"
                  value={values.lName}
                  onChange={handleChange("lName")}
                  label="Last Name"
                  type="text"
                />
                <TextField
                  fullWidth
                  style={{ marginBottom: 14 }}
                  variant="outlined"
                  value={values.phone}
                  onChange={handleChange("phone")}
                  label="Phone"
                  type="tel"
                />
                <FormControl
                  style={{ marginBottom: 14 }}
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel id="demo-simple-select-label">
                    User type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.userType}
                    onChange={handleChange("userType")}
                    label="Age"
                  >
                    <MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"super_user"}>Super User</MenuItem>
                    <MenuItem value={"sales"}>Sales</MenuItem>
                  </Select>
                </FormControl>
                {!editFlow && (
                  <TextField
                    fullWidth
                    style={{ marginBottom: 14 }}
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange("password")}
                    label="Enter Password"
                    type="password"
                  />
                )}
                <div style={{ width: "100%", textAlign: "end" }}>
                  {!editFlow && (
                    <Button
                      size="medium"
                      color="primary"
                      onClick={handleSubmit}
                      variant="contained"
                      style={{ marginRight: 10 }}
                    >
                      Save
                    </Button>
                  )}

                  {editFlow && (
                    <Button
                      size="medium"
                      color="primary"
                      onClick={handleSubmit}
                      variant="contained"
                      style={{ marginRight: 10 }}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    size="medium"
                    onClick={() => handleClose()}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </>
          )}
        </Formik>
        </div>

      </Modal>
      <PageTitle
        title="Settings"
        button={
          <>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={(e) => handleClick(e)}
            >
              <Add />
              Add Users
            </Button>
          </>
        }
      />
      <Grid container spacing={4}>
        {userArr && (
          <Grid item xs={12}>
            <Widget
              numberOfEntries={userArr.length}
              title="User List"
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
            >
              <Table className="mb-0">
                <TableHead>
                  <TableRow>
                    <TableCell key="1">Name</TableCell>
                    <TableCell key="2">Email</TableCell>
                    <TableCell key="3">Amount</TableCell>
                    <TableCell key="4">Phone</TableCell>
                    <TableCell key="5">Added on</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userArr.map(
                    ({
                      _id,
                      fName,
                      lName,
                      emailId,
                      amount,
                      phone,
                      addedAt,
                    }) => (
                      <TableRow key={_id}>
                        <TableCell className="pl-3 fw-normal">
                          {fName + " " + lName}
                        </TableCell>
                        <TableCell>{emailId}</TableCell>
                        <TableCell>{amount}</TableCell>
                        <TableCell>{phone}</TableCell>
                        <TableCell>{addedAt}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Widget>
          </Grid>
        )}
      </Grid>
    </>
  );
}
