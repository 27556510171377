/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { Delete, Edit } from "@material-ui/icons";
import { API_URL } from "../../context/constant";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Typography,
  Button,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Formik } from "formik";

// data
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "material-ui-search-bar";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  longCell: {
    minWidth: "100px",
    maxWidth: "200px",
  },
}));

export default function TaskTable() {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const [isLoading, setIsLoading] = useState(true);

  const [initialValue, setInitialValue] = useState({ status: "" });

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [searched, setSearched] = useState("");
  const [actualData, setActualData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [pressed, setPressed] = useState(false)
  useEffect(() => {
    getTaskInfo();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const getTaskInfo = () => {
    axios
      .post(`${API_URL}/rewards/get`, { type: "daily" }, config)
      .then((res) => {
        if (res.data) {
          const tasks = res.data.data.data;
          let data = [];
          for (const task of tasks) {
            if (task.claimedBy) {
              for (const claim of task.claimedBy) {
                claim.title = task.title;
                claim.goal = task.goal;
                claim.reward = task.reward;
                claim.taskId = task._id;
                data.push(claim);
              }
            }
          }
          console.log("before sort", data);
          const sortedData = [...data].sort(
            (a, b) => new Date(b.lastUpdatedAt) - new Date(a.lastUpdatedAt)
          );
          console.log("after sort", sortedData);
          setClaimData(sortedData);
          setActualData(sortedData);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = actualData.filter((row) => {
      return (
        row.Title.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.Body.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setClaimData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleUpdateReward = (values) => {
    updateTaskToDB(values);
  };

  const updateTaskToDB = (task) => {
    setPressed(true);
    console.log(task);
    axios
      .put(`${API_URL}/rewards/approve/dailyTasks/${task.taskId}`, task, config)
      .then(() => {
        getTaskInfo();
        handleClose();
        toast.success("Task updated Successfully!");
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("Error updating Task!");
        setIsLoading(false);
      });
  };

  const editTask = (task) => {
    setPressed(false);
    setInitialValue(task);
    setOpen(true);
  };

  const deleteTask = (task) => {
    axios
      .delete(`${API_URL}/tasks/delete/${task.id}`, config)
      .then((res) => {
        toast.success("Task deleted succesfully!");
        getTaskInfo();
      })
      .catch(() => {
        toast.error("Unable to delete the Task!");
      });
  };

  const openImg = (imageLink) => {
    window.open(imageLink, "_blank");
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Formik
              initialValues={initialValue}
              onSubmit={(values) => {
                handleUpdateReward(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                dirty,
              }) => (
                <>
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      style={{ marginBottom: 20 }}
                      variant="h3"
                      component="h2"
                    >
                      Status
                    </Typography>

                    <FormControl
                      style={{ marginBottom: 14 }}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Status type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.status}
                        onChange={(e) => {
                          handleChange("status")(e.target.value);
                        }}
                        label="Status"
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"Reject"}>Reject</MenuItem>
                        <MenuItem value={"Approved"}>Approved</MenuItem>
                      </Select>
                    </FormControl>
                    <div style={{ width: "100%", textAlign: "end" }}>
                      <Button
                        disabled={pressed}
                        size="medium"
                        color="primary"
                        disabled={isLoading}
                        onClick={() => {
                          setIsLoading(true);
                          handleSubmit(values);
                        }}
                        variant="contained"
                        style={{ marginRight: 10 }}
                      >
                        Edit
                      </Button>
                      <Button
                        size="medium"
                        onClick={() => handleClose()}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </Modal>
        <PageTitle title="Daily Task Approval" />
        <SearchBar
          style={{ marginBottom: 10 }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <Grid container spacing={4}>
          {actualData && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={claimData.length}
                title="Tasks List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <Table className="mb-0">
                  <TableHead>
                    <TableRow>
                      <TableCell key="1">Email</TableCell>
                      <TableCell key="2">Full Name</TableCell>
                      <TableCell className={classes.longCell} key="3">
                        Link
                      </TableCell>
                      <TableCell key="4">Image</TableCell>
                      <TableCell key="8">Status</TableCell>
                      <TableCell key="5">Title</TableCell>
                      <TableCell key="6">Goal</TableCell>
                      <TableCell key="7">Reward</TableCell>
                      <TableCell key="9">Edit</TableCell>
                      <TableCell key="10">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {claimData.map((claim) => (
                      <TableRow key={claim._id}>
                        <TableCell className="pl-3 fw-normal">
                          {claim.email}
                        </TableCell>
                        <TableCell>{claim.name}</TableCell>
                        <TableCell>
                          {" "}
                          <a target="_blank" rel="noreferrer" href={claim.link}>
                            {claim.link}
                          </a>
                        </TableCell>
                        <TableCell>
                          {
                            <img
                              onClick={() => {
                                openImg(API_URL + "/" + claim.image);
                              }}
                              width={20}
                              height={20}
                              src={API_URL + "/" + claim.image}
                              alt=""
                            />
                          }
                        </TableCell>
                        <TableCell>{claim.status}</TableCell>
                        <TableCell>{claim.title}</TableCell>
                        <TableCell>{claim.goal}</TableCell>
                        <TableCell>{"$" + claim.reward}</TableCell>
                        <TableCell
                          onClick={() => {
                            editTask({
                              status: claim.status,
                              taskId: claim.taskId,
                              userId: claim.userId,
                            });
                          }}
                        >
                          <Edit />
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            deleteTask({
                              id: claim._id,
                              title: claim.title,
                              goal: claim.goal,
                            });
                          }}
                        >
                          <Delete />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay>
    </>
  );
}
