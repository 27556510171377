/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Widget from "../../components/Widget";
import { API_URL } from "../../context/constant";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
  },
}));
export default function UserDetails(props) {
  const [userData, setUserData] = useState(null);
  const [referbyData, setReferbyData] = useState([]);

  const getUserInfo = (user_id) => {
    axios
      .post(`${API_URL}/userMaster/get/`, { emailId: user_id,   deleted: false, })
      .then((res) => {
        if (res.data) {
          setUserData(res.data.data.data[0]);
          // console.log('I am refereal data',res.data.data.data[0].referalBy);
          if (res.data.data.data[0].referalCode) {
            getReferlLevelUser(res.data.data.data[0].referalCode);
          }
        }
      });
  };

  const getReferlLevelUser = (referby) => {
    axios
      .post(`${API_URL}/userMaster/get/`, { referalBy: referby })
      .then((res) => {
        // console.log( " i am zero data", res.data.data.data[0]);
        if (res.data.data.data) {
          // console.log("I am data", res.data.data.data);
          setReferbyData(res.data.data.data);
        }
      });
  };

  const classes = useStyles();
  useEffect(() => {
    // console.log("Values", props.location);
    const search_prop = props.location.search;
    const user_id = search_prop.replace("?", "");
    if (user_id) {
      // console.log( 'Im user id', user_id);
      getUserInfo(user_id);
    }
  }, []);
  return (
    <>
      <PageTitle title="User Details" />
      <Grid container spacing={4}>
        {userData && (
          <Widget
            numberOfEntries={""}
            title="Personal Information"
            style={{ width: "100%" }}
            upperTitle
            bodyClass={classes.tableOverflow}
          >
            <Typography id="modal-modal-description" sx={{ mt: 10 }}>
              Transaction Address Update -{" "}
              {userData.transactionAddressUpdate ? "Yes" : "No"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              FName - {userData.fName}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              LName - {userData.lName}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Email - {userData.emailId}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Phone - {userData.phone}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Referal Code -{" "}
              {userData.referalCode ? userData.referalCode : "Null"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Deleted - {userData.deleted ? "Yes" : "No"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Transaction Address -{" "}
              {userData.transactionAddress
                ? userData.transactionAddress
                : "Null"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Referal By - {userData.referalBy ? userData.referalBy : "Null"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Amount - {userData.amount}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Available Balance -{" "}
              {userData.availableBalance ? userData.availableBalance : "0"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Withdraw Balance -{" "}
              {userData.withdrawBalance ? userData.withdrawBalance : "0"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Trade Usdt - {userData.tradeUsdt}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Level - {userData.level}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Last Login - {userData.lastUpdatedAt}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              UserId - {userData.userId ? userData.userId : "Null"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              IP Address - {userData.macAddress ? userData.macAddress : "Null"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 5 }}>
              Account Created On - {userData.addedAt}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 5 }}
              style={{ marginTop: "4%" }}
            >
              <b>My Team Referal</b>

              {referbyData.length && (
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ border: "1px solid #000", borderWidth: 1 }}
                >
                  <TableHead>
                    <TableRow
                      style={{ border: "1px solid #000", borderWidth: 1 }}
                    >
                      <TableCell>S.NO</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Level</TableCell>
                      <TableCell>UserId</TableCell>
                      <TableCell>Created time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {referbyData.length &&
                      referbyData.map((referedUser, index) => (
                        <TableRow
                          hover
                          style={{ border: "1px solid #000", borderWidth: 1 }}
                          key={index}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{referedUser._id}</TableCell>
                          <TableCell>Lv{referedUser.Updatelevel}</TableCell>
                          <TableCell>
                            {referedUser && referedUser.userId
                              ? referedUser.userId
                              : "N/A"}
                          </TableCell>
                          <TableCell>{referedUser.addedAt}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </Typography>
          </Widget>
        )}
      </Grid>
    </>
  );
}
