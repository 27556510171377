import React, { useState } from "react";
import { Formik } from "formik";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import AddCardIcon from "@mui/icons-material/AddCard";
import { toast } from "react-toastify";

import axios from "axios";
import { API_URL } from "../../../../context/constant";

export default function TableComponent(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [initialValue, setInitialValue] = useState({});

  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  const openUserDetails = (user) => {
    // console.log("User", props);
    props.history.push({
      pathname: "/app/userDetails",
      search: user.emailId,
      state: { userDetail: user },
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const editUser = (userData) => {
    props.parentOpenModal(userData);
  };

  const deleteUser = (user) => {
    // console.log(user.userId);
    axios
      .delete(`${API_URL}/userMaster/delete/${user._id}`)
      .then((res) => {
        // console.log(res.data);
        toast.success("User deleted succesfully!");
        props.parentCallback();
      })
      .catch(() => {
        toast.error("Unable to delete the user!");
      });
  };

  const handleCreditDebitClick = (userData) => {
    setInitialValue({ ...userData, option: "", enterAmount: 0, remark: "" });
    // console.log(userData);
    handleOpen();
  };

  const handleBalanceChange = (values) => {
    // console.log(values);
    axios
      .put(
        `${API_URL}/editFunds/add/${values._id}`,
        {
          amount: values?.enterAmount,
          remark: values?.remark,
          fundDetail: values?.option,
        },
        config
      )
      .then((res) => {
        console.log("Success");
        handleClose();
        toast.success(`${values?.enterAmount} ${values?.option} Successful!`);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Error while Credit/Debit!");
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={(values) => {
              handleBalanceChange(values);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              dirty,
            }) => (
              <>
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    style={{ marginBottom: 20 }}
                    variant="h3"
                    component="h2"
                  >
                    Credit / Debit
                  </Typography>
                  <TextField
                    fullWidth
                    style={{ marginBottom: 14 }}
                    variant="outlined"
                    disabled={true}
                    value={values.emailId}
                    onChange={handleChange("emailId")}
                    label="Email"
                    type="email"
                  />
                  <TextField
                    fullWidth
                    style={{ marginBottom: 14 }}
                    variant="outlined"
                    disabled={true}
                    value={values.amount}
                    onChange={handleChange("amount")}
                    label="Amount"
                    type="number"
                  />

                  <FormControl
                    style={{ marginBottom: 14 }}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-label">
                      {" "}
                      Credit/Debit
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.option}
                      onChange={(e) => {
                        handleChange("option")(e.target.value);
                      }}
                      label="Option"
                    >
                      <MenuItem value={"Credit"}>Credit</MenuItem>
                      <MenuItem value={"Debit"}>Debit</MenuItem>
                    </Select>
                    <TextField
                      fullWidth
                      style={{ marginTop: 14 }}
                      variant="outlined"
                      value={values.enterAmount}
                      onChange={handleChange("enterAmount")}
                      label="Enter Amount"
                      type="number"
                    />
                    <TextField
                      fullWidth
                      style={{ marginTop: 14 }}
                      variant="outlined"
                      value={values.remark}
                      onChange={handleChange("remark")}
                      label="Remark"
                      type="string"
                    />
                  </FormControl>

                  <div style={{ width: "100%", textAlign: "end" }}>
                    <Button
                      size="medium"
                      color="primary"
                      onClick={handleSubmit}
                      variant="contained"
                      style={{ marginRight: 10 }}
                    >
                      Save
                    </Button>
                    <Button
                      size="medium"
                      onClick={() => handleClose()}
                      color="primary"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </div>
                </Box>
              </>
            )}
          </Formik>
        </div>
      </Modal>

      <Table className="mb-0">
        <TableHead>
          <TableRow>
            <TableCell key="1">Name</TableCell>
            <TableCell key="2">Email</TableCell>
            <TableCell key="3">Amount</TableCell>
            <TableCell key="4">Phone</TableCell>
            <TableCell key="5">IP address</TableCell>
            <TableCell key="6">Added on</TableCell>
            <TableCell key="7">Edit</TableCell>
            <TableCell key="8">Delete</TableCell>
            <TableCell
              key="9"
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "120px",
              }}
            >
              Edit Funds
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map(
            ({
              _id,
              transactionAddressUpdate,
              impsAddressUpdate,
              fName,
              lName,
              emailId,
              phone,
              referalCode,
              deleted,
              transactionAddress,
              impsAddress,
              referalBy,
              amount,
              availableBalance,
              withdrawBalance,
              tradeUsdt,
              level,
              lastUpdatedAt,
              userId,
              addedAt,
              child,
              macAddress,
              userType,
            }) =>
              userType === "user" && (
                <TableRow key={_id}>
                  <TableCell
                    onClick={() => {
                      openUserDetails({
                        _id,
                        transactionAddressUpdate,
                        impsAddressUpdate,

                        fName,
                        lName,
                        emailId,
                        phone,
                        referalCode,
                        deleted,
                        transactionAddress,
                        impsAddress,
                        referalBy,
                        amount,
                        availableBalance,
                        withdrawBalance,
                        tradeUsdt,
                        level,
                        lastUpdatedAt,
                        userId,
                        addedAt,
                        child,
                        macAddress,
                      });
                    }}
                    className="pl-3 fw-normal"
                  >
                    {fName + " " + lName}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      openUserDetails({
                        _id,
                        transactionAddressUpdate,
                        impsAddressUpdate,

                        fName,
                        lName,
                        emailId,
                        phone,
                        referalCode,
                        deleted,
                        transactionAddress,
                        impsAddress,
                        referalBy,
                        amount,
                        availableBalance,
                        withdrawBalance,
                        tradeUsdt,
                        level,
                        lastUpdatedAt,
                        userId,
                        addedAt,
                        child,
                        macAddress,
                      });
                    }}
                  >
                    {emailId}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      openUserDetails({
                        _id,
                        transactionAddressUpdate,
                        impsAddressUpdate,

                        fName,
                        lName,
                        emailId,
                        phone,
                        referalCode,
                        deleted,
                        transactionAddress,
                        impsAddress,
                        referalBy,
                        amount,
                        availableBalance,
                        withdrawBalance,
                        tradeUsdt,
                        level,
                        lastUpdatedAt,
                        userId,
                        addedAt,
                        child,
                        macAddress,
                      });
                    }}
                  >
                    ${parseFloat(availableBalance).toFixed(4)}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      openUserDetails({
                        _id,
                        transactionAddressUpdate,
                        impsAddressUpdate,

                        fName,
                        lName,
                        emailId,
                        phone,
                        referalCode,
                        deleted,
                        transactionAddress,
                        impsAddress,
                        referalBy,
                        amount,
                        availableBalance,
                        withdrawBalance,
                        tradeUsdt,
                        level,
                        lastUpdatedAt,
                        userId,
                        addedAt,
                        child,
                        macAddress,
                      });
                    }}
                  >
                    {phone}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      openUserDetails({
                        _id,
                        transactionAddressUpdate,
                        impsAddressUpdate,

                        fName,
                        lName,
                        emailId,
                        phone,
                        referalCode,
                        deleted,
                        transactionAddress,
                        impsAddress,
                        referalBy,
                        amount,
                        availableBalance,
                        withdrawBalance,
                        tradeUsdt,
                        level,
                        lastUpdatedAt,
                        userId,
                        addedAt,
                        child,
                        macAddress,
                      });
                    }}
                  >
                    {macAddress ? macAddress : "-"}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      openUserDetails({
                        _id,
                        transactionAddressUpdate,
                        impsAddressUpdate,
                        fName,
                        lName,
                        emailId,
                        phone,
                        referalCode,
                        deleted,
                        transactionAddress,
                        impsAddress,
                        referalBy,
                        amount,
                        availableBalance,
                        withdrawBalance,
                        tradeUsdt,
                        level,
                        lastUpdatedAt,
                        userId,
                        addedAt,
                        child,
                        macAddress,
                      });
                    }}
                  >
                    {addedAt}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      editUser({
                        _id,
                        fName,
                        lName,
                        emailId,
                        amount,
                        phone,
                        transactionAddressUpdate,
                        impsAddressUpdate,
                        transactionAddress,
                        impsAddress,
                        availableBalance,
                        deleted,
                        addedAt,
                      });
                    }}
                  >
                    <Edit />
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      deleteUser({
                        _id,
                        fName,
                        lName,
                        emailId,
                        amount,
                        phone,
                        addedAt,
                      });
                    }}
                  >
                    <Delete />
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleCreditDebitClick({
                        _id,
                        emailId,
                        amount: availableBalance,
                      });
                    }}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <AddCardIcon />
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </div>
  );
}
