import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";

//icons
import {} from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import Announcements from "../../pages/announcements/Table";
// import Tasks from "../../pages/dailyTasks/Table"
import { useLayoutState } from "../../context/LayoutContext";
import CoinTables from "../../pages/coins/Tables";
import Withdrawals from "../../pages/withdrawal/withdrawal";
import Kyc from "../../pages/kyc/kyc";
import Deposits from "../../pages/deposit/deposit";
import Settings from "../../pages/settings/settings";
import UserDetails from "../../pages/userDetail/userDetails";
import QRPayment from "../../pages/qrPayment/qrPayment";
import Tasks from "../../pages/tasks/Tasks";
import MainTable from "../../pages/dailyTasks/Table";
function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/cointable" component={CoinTables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/app/deposit" component={Deposits} />
            <Route path="/app/withdrawal" component={Withdrawals} />
            <Route path="/app/kyc" component={Kyc} />
            <Route path="/app/settings" component={Settings} />
            <Route path="/app/userDetails" component={UserDetails} />
            <Route path="/app/qrPayment" component={QRPayment} />
            <Route path="/app/announcements" component={Announcements} />
            <Route path="/app/tasks" component={Tasks} />
            <Route path="/app/dailyTaskApproval" component={MainTable} />

            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
