/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Add } from "@material-ui/icons";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { API_URL } from "../../context/constant";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";

// data
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "material-ui-search-bar";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  longCell: {
    minWidth: "250px",
  },
  loader: {
    background: "transparent",
  },
}));

export default function QRPayment(props) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [editFlow, setEditFlow] = React.useState(false);
  const [impsDetailsError, setImpsDetailsError] = useState(false);
  const [initialValue, setInitialValue] = useState({
    paymentAddress: "",
    bankAccountNo: "",
    nameInBank: "",
    bankIFSC: "",
    file: null,
    method: "TRC20",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searched, setSearched] = useState("");
  const [actualData, setActualData] = useState([]);
  const [userArr, setUserArr] = useState([]);
  useEffect(() => {
    getUserInfo();
  }, []);

  const addPaymentValidation = yup.object().shape({
    paymentAddress: yup.string(),
    nameInBank: yup.string(),
    bankIFSC: yup.string(),
    bankAccountNo: yup.number(),
    file: yup.string().required("QR image is Required"),
  });

  const getUserInfo = () => {
    axios
      .post(`${API_URL}/qrcode/get`, null, config)
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.data.data.map((qrData) => {
            if (qrData.method === "IMPS") {
              const parsedData = JSON.parse(qrData.paymentAddress);
              qrData.bankAccountNo = parsedData?.bankAccountNo;
              qrData.nameInBank = parsedData?.nameInBank;
              qrData.bankIFSC = parsedData?.bankIFSC;
            }

            return qrData;
          });

          setUserArr(formattedData);
          setActualData(formattedData);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleClick = () => {
    setInitialValue({
      paymentAddress: "",
      bankAccountNo: "",
      nameInBank: "",
      bankIFSC: "",
      file: null,
      method: "TRC20",
    });
    setEditFlow(false);
    handleOpen();
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = actualData.filter((row) => {
      return row.paymentAddress
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setUserArr(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleCreateQr = (values) => {
    if (values.method === "IMPS") {
      // if (
      //   values.bankAccountNo === "" ||
      //   values.bankIFSC === "" ||
      //   values.nameInBank === ""
      // ) {
      //   setImpsDetailsError(true);
      //   return;
      // }
    }
    const impsAddress = {
      bankAccountNo: values.bankAccountNo,
      bankIFSC: values.bankIFSC,
      nameInBank: values.nameInBank,
    };
    axios
      .post(
        `${API_URL}/qrcode/add`,
        {
          paymentAddress:
            values.method === "TRC20"
              ? values.paymentAddress
              : JSON.stringify(impsAddress),
          method: values.method,
        },
        config
      )
      .then((res) => {
        if (res.data) {
          const formdata = new FormData();
          formdata.append("file", values.file);
          axios
            .patch(
              `${API_URL}/qrcode/multipleUpload/${res.data.data._id}`,
              formdata,
              config
            )
            .then((res) => {
              toast.success("Successfully Added Payment");
              getUserInfo();
              handleClose();
            })
            .catch(() => {
              toast.error("Error adding Paymenttt !");
            });
        }
      })
      .catch(() => {
        toast.error("Error adding Payment !");
      });
  };

  const seeQR = (qrCodeLink) => {
    window.open(`${API_URL}/${qrCodeLink}`, "_blank");
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Formik
              enableReinitialize
              validationSchema={addPaymentValidation}
              initialValues={initialValue}
              onSubmit={(values) => {
                handleCreateQr(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                dirty,
                setFieldValue,
              }) => (
                <>
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      style={{ marginBottom: 20 }}
                      variant="h3"
                      component="h2"
                    >
                      Add QR Payment
                    </Typography>
                    {values.method === "TRC20" ? (
                      <TextField
                        fullWidth
                        style={{ marginBottom: 14 }}
                        variant="outlined"
                        value={values.paymentAddress}
                        onChange={handleChange("paymentAddress")}
                        label="Payment Address"
                        type="text"
                      />
                    ) : (
                      <div>
                        <TextField
                          fullWidth
                          style={{ marginBottom: 14 }}
                          variant="outlined"
                          value={values.bankAccountNo}
                          onChange={(e) => {
                            setFieldValue("bankAccountNo", e.target.value);
                            setImpsDetailsError(false);
                          }}
                          label="Bank Account No"
                          type="number"
                        />
                        {impsDetailsError && values.bankAccountNo === "" && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {"Please Enter Bank Account Number"}
                            </span>
                          </div>
                        )}
                        <TextField
                          fullWidth
                          style={{ marginBottom: 14 }}
                          variant="outlined"
                          value={values.bankIFSC}
                          onChange={(e) => {
                            setFieldValue("bankIFSC", e.target.value);
                            setImpsDetailsError(false);
                          }}
                          label="IFSC Code"
                          type="text"
                        />
                        {impsDetailsError && values.bankIFSC === "" && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {"Please Enter Bank IFSC Code"}
                            </span>
                          </div>
                        )}
                        <TextField
                          fullWidth
                          style={{ marginBottom: 14 }}
                          variant="outlined"
                          value={values.nameInBank}
                          onChange={(e) => {
                            setFieldValue("nameInBank", e.target.value);
                            setImpsDetailsError(false);
                          }}
                          label="Account holder's name"
                          type="text"
                        />
                        {impsDetailsError && values.nameInBank === "" && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {"Please Enter Account Holders name "}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    <div id="d-flex flex-row p-3">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.1rem",
                          marginRight: "0.4rem",
                        }}
                      >
                        Choose Method
                      </span>
                      <span>
                        <label htmlFor="TRC20">
                          <input
                            type="radio"
                            id="TRC20"
                            name="TRC20"
                            value="TRC20"
                            checked={values.method === "TRC20"}
                            onChange={(e) => {
                              setFieldValue("method", e.target.value);
                            }}
                          />{" "}
                          TRC20
                        </label>
                        <label htmlFor="IMPS">
                          <input
                            type="radio"
                            id="IMPS"
                            name="IMPS"
                            value="IMPS"
                            checked={values.method === "IMPS"}
                            onChange={(e) => {
                              setFieldValue("method", e.target.value);
                            }}
                          />{" "}
                          IMPS
                        </label>
                      </span>
                    </div>

                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <span style={{ marginRight: 10 }}>QR Image upload</span>
                      <input
                        name="file"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                        }}
                      ></input>
                    </div>
                    <div style={{ width: "100%", textAlign: "end" }}>
                      {!editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Save
                        </Button>
                      )}

                      {editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        size="medium"
                        onClick={() => handleClose()}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </Modal>
        <PageTitle
          title="QR Payment"
          button={
            <>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={(e) => handleClick(e)}
              >
                <Add />
                Add
              </Button>
            </>
          }
        />
        <SearchBar
          style={{ marginBottom: 10 }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <Grid container spacing={4}>
          {actualData && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={userArr.length}
                title="User List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <Table className="mb-0">
                  <TableHead>
                    <TableRow>
                      <TableCell key="1" className={classes.longCell}>
                        Payment Address
                      </TableCell>
                      <TableCell key="2">Image</TableCell>
                      <TableCell key="3">Active</TableCell>
                      <TableCell key="4">Method</TableCell>

                      <TableCell key="5">Added on</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userArr.map((qr) => (
                      <TableRow key={qr._id}>
                        <TableCell
                          className="pl-3 fw-normal"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {qr.method === "IMPS"
                            ? `Bank Account Number: ${qr.bankAccountNo} \n IFSC Code: ${qr.bankIFSC} \n Account Holder's Name: ${qr.nameInBank}`
                            : qr.paymentAddress}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            seeQR(qr.image);
                          }}
                        >
                          <img
                            src={`${API_URL}/${qr.image}`}
                            style={{ height: 120, width: 120 }}
                            alt=""
                          />
                        </TableCell>
                        <TableCell>{qr.isActive ? "Yes" : "No"}</TableCell>
                        <TableCell>{qr.method}</TableCell>
                        <TableCell>{qr.addedAt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay>
    </>
  );
}
