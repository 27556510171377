/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { Delete, Edit } from "@material-ui/icons";
import { API_URL } from "../../context/constant";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Modal,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Formik } from "formik";
import * as yup from "yup";

// data
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "material-ui-search-bar";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default function CoinTables() {
  const [pressed, setPressed] = useState(false)
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const [isLoading, setIsLoading] = useState(true);

  const [editFlow, setEditFlow] = React.useState(false);
  const [initialValue, setInitialValue] = useState({
    type: "",
    percentage: "",
    miniMumPayment: "",
    days: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searched, setSearched] = useState("");
  const [actualData, setActualData] = useState([]);
  const [userArr, setUserArr] = useState([]);
  useEffect(() => {
    getUserInfo();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const addCoinValidation = yup.object().shape({
    type: yup.string().required("Email Address is Required"),
    percentage: yup.string().required("Percentage is Required"),
    miniMumPayment: yup.string().required("Minimum payment is Required"),
    days: yup.string().required("Days are Required"),
  });

  const getUserInfo = () => {
    axios
      .post(`${API_URL}/coindetail/get`, null, config)
      .then((res) => {
        // console.log("CoinDetails>>>", res.data.data.data);
        if (res.data) {
          setUserArr(res.data.data.data);
          setActualData(res.data.data.data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = actualData.filter((row) => {
      return row.type.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setUserArr(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleClick = () => {
    setInitialValue({ type: "", percentage: "", miniMumPayment: "", days: "" });
    setEditFlow(false);
    handleOpen();
  };

  const handleCreateCoin = (values) => {
    if (!editFlow) {
      addCoinToDb(values);
    } else {
      updateCoinToDB(values);
    }
  };

  const updateCoinToDB = (coinData) => {
    setPressed(true);
    // console.log(coinData);
    axios
      .put(`${API_URL}/coindetail/update/${coinData._id}`, coinData, config)
      .then(() => {
        getUserInfo();
        handleClose();
        toast.success("Coin updated Successfully!");
      })
      .catch(() => {
        toast.error("Error updating coin!");
      });
  };

  const addCoinToDb = async (coinData) => {
    axios
      .post(`${API_URL}/coindetail/add`, coinData)
      .then(async (res) => {
        // console.log(res.data.data.data);
        if (res.data.status === "OK") {
          getUserInfo();
          await toast.success("Coin Added Successfully!");
        } else {
          await toast.error("Error while adding coin!");
        }
        handleClose();
      })
      .catch(async (err) => {
        await toast.error("Error while adding coin!");
      });
  };

  const editCoin = (coinData) => {
    setPressed(false);
    setInitialValue(coinData);
    setEditFlow(true);
    setOpen(true);
  };

  const deleteCoin = (CoinData) => {
    // console.log(CoinData);
    axios
      .delete(`${API_URL}/coindetail/delete/${CoinData._id}`, config)
      .then((res) => {
        // console.log(res.data);
        toast.success("Coin deleted succesfully!");
        getUserInfo();
      })
      .catch(() => {
        toast.error("Unable to delete the Coin!");
      });
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>


            <Formik
              enableReinitialize
              validationSchema={addCoinValidation}
              initialValues={initialValue}
              onSubmit={(values) => {
                handleCreateCoin(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                dirty,
              }) => (
                <>
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      style={{ marginBottom: 20 }}
                      variant="h3"
                      component="h2"
                    >
                      Add Coin
                    </Typography>
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.type}
                      onChange={handleChange("type")}
                      label="Type"
                      type="text"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.percentage}
                      onChange={handleChange("percentage")}
                      label="Percentage"
                      type="number"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.miniMumPayment}
                      onChange={handleChange("miniMumPayment")}
                      label="Minimum Payment"
                      type="tel"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.days}
                      onChange={handleChange("days")}
                      label="Days"
                      type="number"
                    />
                    <div style={{ width: "100%", textAlign: "end" }}>
                      {!editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Save
                        </Button>
                      )}

                      {editFlow && (
                        <Button
                          disabled={pressed}
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        size="medium"
                        onClick={() => handleClose()}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </Modal>
        <PageTitle
          title="Coins"
          button={
            <>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={(e) => handleClick(e)}
              >
                <Add />
                Add Coins
              </Button>
            </>
          }
        />
        <SearchBar
          style={{ marginBottom: 10 }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <Grid container spacing={4}>
          {actualData && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={userArr.length}
                title="Coins List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <Table className="mb-0">
                  <TableHead>
                    <TableRow>
                      <TableCell key="1">Type</TableCell>
                      <TableCell key="2">Days</TableCell>
                      <TableCell key="3">Min. Amount ($)</TableCell>
                      <TableCell key="4">Percentage</TableCell>
                      <TableCell key="5">AddedAt</TableCell>
                      <TableCell key="6">Edit</TableCell>
                      <TableCell key="7">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userArr.map(
                      ({
                        _id,
                        type,
                        days,
                        miniMumPayment,
                        percentage,
                        addedAt,
                      }) => (
                        <TableRow key={_id}>
                          <TableCell className="pl-3 fw-normal">
                            {type}
                          </TableCell>
                          <TableCell>{days}</TableCell>
                          <TableCell>{miniMumPayment}</TableCell>
                          <TableCell>{percentage}</TableCell>
                          <TableCell>{addedAt}</TableCell>
                          <TableCell
                            onClick={() => {
                              editCoin({
                                _id,
                                type,
                                days,
                                miniMumPayment,
                                percentage,
                                addedAt,
                              });
                            }}
                          >
                            <Edit />
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              deleteCoin({
                                _id,
                                type,
                                days,
                                miniMumPayment,
                                percentage,
                                addedAt,
                              });
                            }}
                          >
                            <Delete />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay >
    </>
  );
}
