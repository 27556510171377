import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  SupervisedUserCircle,
  Adjust,
  AccountBalanceOutlined,
  FormatAlignRight,
  Settings,
  Payment,
  Announcement,
  AssignmentTurnedIn,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  const [menu, setMenu] = useState([]);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    const data = localStorage.getItem("userData");
    if (data) {
      const userData = JSON.parse(data);
      if (userData) {
        if (userData.userType === "admin") {
          setMenu([
            {
              id: 0,
              label: "Dashboard",
              link: "/app/dashboard",
              icon: <HomeIcon />,
            },
            {
              id: 4,
              label: "Deposits",
              link: "/app/deposit",
              icon: <AccountBalanceOutlined />,
            },
            {
              id: 6,
              label: "KYC",
              link: "/app/kyc",
              icon: <FormatAlignRight />,
            },
            { id: 5, type: "divider" },
          ]);
        } else if (userData.userType === "sales") {
          setMenu([
            {
              id: 0,
              label: "Dashboard",
              link: "/app/dashboard",
              icon: <HomeIcon />,
            },
            {
              id: 1,
              label: "Users",
              link: "/app/tables",
              icon: <SupervisedUserCircle />,
            },
            {
              id: 2,
              label: "Withdrawals",
              link: "/app/withdrawal",
              icon: <FormatAlignRight />,
            },
            { id: 5, type: "divider" },
          ]);
        } else if (userData.userType === "super_user") {
          setMenu([
            {
              id: 0,
              label: "Dashboard",
              link: "/app/dashboard",
              icon: <HomeIcon />,
            },
            {
              id: 1,
              label: "Users",
              link: "/app/tables",
              icon: <SupervisedUserCircle />,
            },
            {
              id: 2,
              label: "Announcements",
              link: "/app/announcements",
              icon: <Announcement />,
            },
            {
              id: 3,
              label: "Coin Details",
              link: "/app/cointable",
              icon: <Adjust />,
            },
            {
              id: 4,
              label: "Deposits",
              link: "/app/deposit",
              icon: <AccountBalanceOutlined />,
            },
            {
              id: 5,
              label: "Withdrawals",
              link: "/app/withdrawal",
              icon: <FormatAlignRight />,
            },
            {
              id: 7,
              label: "KYC",
              link: "/app/kyc",
              icon: <FormatAlignRight />,
            },
            {
              id: 6,
              label: "QR Payment",
              link: "/app/qrPayment",
              icon: <Payment />,
            },
            {
              id: 10,
              label: "Tasks",
              link: "/app/tasks",
              icon: <AssignmentTurnedIn />,
            },
            {
              id: 11,
              label: "Daily Task Approval",
              link: "/app/dailyTaskApproval",
              icon: <AssignmentTurnedIn />,
            },
            {
              id: 8,
              label: "Settings",
              link: "/app/settings",
              icon: <Settings />,
            },
            { id: 9, type: "divider" },
          ]);
        }
      }
    }
  }, []);
  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>

      <List className={classes.sidebarList}>
        {menu.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            toggleSidebar={toggleSidebar}
            layoutDispatch={layoutDispatch}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
