import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import { toast } from "react-toastify";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Typography,
  Button,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Edit } from "@material-ui/icons";
import { API_URL } from "../../context/constant";

// data
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  loader: {
    background: "transparent",
  },
}));

export default function Kyc(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [editFlow, setEditFlow] = React.useState(false);
  const [initialValue, setInitialValue] = useState({ approveStatus: "" });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searched, setSearched] = useState("");
  const [actualData, setActualData] = useState([]);
  const [userArr, setUserArr] = useState([]);
  useEffect(() => {
    getUserInfo();
  }, []);

  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  const getUserInfo = () => {
    axios
      .post(`${API_URL}/userMaster/get`, null)
      .then((res) => {
        // console.log("data", res.data.data.data);
        if (res.data) {
          let sortedData = res.data.data.data.sort((a, b) => a.kycUpdated - b.kycUpdated);
          sortedData = sortedData.filter((data) => data.kycUpdated);
          setUserArr(sortedData);
          setActualData(sortedData);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const requestSearch = (searchedVal) => {
    const filteredRows = actualData.filter((row) => {
      return row.emailId.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setUserArr(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleCreateUser = (values) => {
    // console.log("Values", values, editFlow);

    if (editFlow) {
      addUserToDb(values);
      handleClose();
    }
  };

  const addUserToDb = async (values) => {
    axios
      .put(
        `${API_URL}/userMaster/approve/${values._id}`,
        { status: values?.approveStatus },
        config
      )
      .then(async (res) => {
        // console.log("res", res);
        if (res.data.status === "OK") {
          getUserInfo();
          toast.success("Kyc Status Successfully!");
        } else {
          toast.error("Error while updating user!");
        }
        handleClose();
      })
      .catch(async (err) =>   {
        toast.error("Error while adding user!");
      });
  };

  const openEditModal = (values) => {
    // console.log("Edit Values", values);
    setInitialValue(values);
    setEditFlow(true);
    handleOpen(true);
  };

  const openImg = (imageLink) => {
    window.open(imageLink, "_blank");
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>

          
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={(values) => {
              handleCreateUser(values);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              dirty,
            }) => (
              <>
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    style={{ marginBottom: 20 }}
                    variant="h3"
                    component="h2"
                  >
                    Add User
                  </Typography>

                  <FormControl
                    style={{ marginBottom: 14 }}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Status type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.approveStatus}
                      onChange={(e) => {
                        // console.log("details status", e.target.value);
                        handleChange("approveStatus")(e.target.value);
                      }}
                      label="Status"
                    >
                      <MenuItem value={"Pending"}>Pending</MenuItem>
                      <MenuItem value={"Reject"}>Reject</MenuItem>
                      <MenuItem value={"Approved"}>Approved</MenuItem>
                    </Select>
                  </FormControl>
                  <div style={{ width: "100%", textAlign: "end" }}>
                    {/* {console.log("errorsssss", errors)} */}
                    {editFlow && (
                      <Button
                        size="medium"
                        color="primary"
                        onClick={handleSubmit}
                        variant="contained"
                        style={{ marginRight: 10 }}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      size="medium"
                      onClick={() => handleClose()}
                      color="primary"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </div>
                </Box>
              </>
            )}
          </Formik>
          </div>
        </Modal>
        <PageTitle title="KYC Users" />
        <SearchBar
          style={{ marginBottom: 10 }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <Grid container spacing={4}>
          {actualData && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={userArr.length}
                title="User List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <Table className="mb-0">
                  <TableHead>
                    <TableRow>
                      <TableCell key="1">Email</TableCell>
                      <TableCell key="2">Name on Card</TableCell>
                      <TableCell key="3">Id number</TableCell>
                      <TableCell key="4">Country</TableCell>
                      <TableCell key="5">Status</TableCell>
                      <TableCell key="6">Document</TableCell>
                      <TableCell key="7">AddedAt</TableCell>
                      <TableCell key="8">Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userArr.map(
                      ({
                        _id,
                        idNo,
                        emailId,
                        nameOnIdCard,
                        country,
                        document,
                        approveStatus,
                        addedAt,
                      }) => (
                        <TableRow key={_id}>
                          <TableCell className="pl-3 fw-normal">
                            {emailId}
                          </TableCell>
                          <TableCell>{nameOnIdCard}</TableCell>
                          <TableCell>{idNo}</TableCell>
                          <TableCell>{country}</TableCell>
                          <TableCell>{approveStatus}</TableCell>
                          <TableCell>
                            {document.length && (
                              <>
                                {document[0] && (
                                  <img
                                    onClick={() => {
                                      openImg(
                                        API_URL +
                                          "/" +
                                          document[document.length - 3]
                                      );
                                    }}
                                    width={20}
                                    height={20}
                                    src={
                                      API_URL +
                                      "/" +
                                      document[document.length - 3]
                                    }
                                    alt=""
                                  />
                                )}
                                {document[1] && (
                                  <img
                                    onClick={() => {
                                      openImg(
                                        API_URL +
                                          "/" +
                                          document[document.length - 2]
                                      );
                                    }}
                                    width={20}
                                    height={20}
                                    style={{ marginLeft: 10 }}
                                    src={
                                      API_URL +
                                      "/" +
                                      document[document.length - 2]
                                    }
                                    alt=""
                                  />
                                )}
                                {document[2] && (
                                  <img
                                    onClick={() => {
                                      openImg(
                                        API_URL +
                                          "/" +
                                          document[document.length - 1]
                                      );
                                    }}
                                    width={20}
                                    height={20}
                                    style={{ marginLeft: 20 }}
                                    src={
                                      API_URL +
                                      "/" +
                                      document[document.length - 1]
                                    }
                                    alt=""
                                  />
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell>{addedAt}</TableCell>
                          <TableCell
                            onClick={() => {
                              openEditModal({
                                _id,
                                idNo,
                                emailId,
                                nameOnIdCard,
                                country,
                                approveStatus,
                                addedAt,
                              });
                            }}
                          >
                            <Edit />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay>
    </>
  );
}
