/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { Delete, Edit } from "@material-ui/icons";
import { API_URL } from "../../context/constant";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Modal,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Formik } from "formik";
import * as yup from "yup";

// data
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import SearchBar from "material-ui-search-bar";
LoadingOverlay.propTypes = undefined;

const useStyles = makeStyles((theme) => ({
  longCell: {
    minWidth: "250px",
  },
  tableOverflow: {
    overflow: "auto",
  },
}));

const Tasks = () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const [isLoading, setIsLoading] = useState(true);

  const [editFlow, setEditFlow] = React.useState(false);
  const [initialValue, setInitialValue] = useState({
    title: "",
    reward: "",
    goal: "",
    activeUsersRequired: "",
    type: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searched, setSearched] = useState("");
  const [actualData, setActualData] = useState([]);
  const [tasksArr, setTasksArr] = useState([]);
  const [pressed, setPressed] = useState(false);
  const [activeUsersRequiredError, setActiveUsersRequiredError] =
    useState(false);

  const getTasksInfo = () => {
    axios
      .post(`${API_URL}/rewards/get`, null, config)
      .then((res) => {
        if (res.data) {
          setTasksArr(res.data.data.data);
          setActualData(res.data.data.data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTasksInfo();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const addTaskValidation = yup.object().shape({
    title: yup.string().required("title is Required"),
    reward: yup.number().required("reward is Required"),
    goal: yup.string().required("goal is Required"),
    activeUsersRequired: yup.number(),
    type: yup.string().required("type is Required"),
  });

  const requestSearch = (searchedVal) => {
    const filteredRows = actualData.filter((row) => {
      return (
        row.title.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.goal.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setTasksArr(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleClick = () => {
    setInitialValue({
      title: "",
      reward: "",
      goal: "",
      activeUsersRequired: "",
      type: "",
    });
    setEditFlow(false);
    handleOpen();
  };

  const handleCreateTask = (values) => {
    if (!editFlow) {
      addTaskToDb(values);
    } else {
      updateTaskToDB(values);
    }
  };

  const updateTaskToDB = (taskData) => {
    setPressed(true);
    if (taskData.type === "daily") {
      taskData.activeUsersRequired = 1;
    } else {
      if (taskData.activeUsersRequired === "") {
        setActiveUsersRequiredError(true);
        return;
      }
    }
    axios
      .put(`${API_URL}/rewards/update/${taskData._id}`, taskData, config)
      .then(() => {
        getTasksInfo();
        handleClose();
        toast.success("Task updated Successfully!");
      })
      .catch(() => {
        toast.error("Error updating task!");
      });
  };

  const addTaskToDb = async (taskData) => {
    if (taskData.type === "daily") {
      taskData.activeUsersRequired = 1;
    } else {
      if (taskData.activeUsersRequired === "") {
        setActiveUsersRequiredError(true);
        return;
      }
    }
    axios
      .post(`${API_URL}/rewards/add`, taskData, config)
      .then(async (res) => {
        console.log(res.data.data);
        if (res.data.status === "OK") {
          getTasksInfo();
          toast.success("Task Added Successfully!");
        } else {
          toast.error("Error while adding task!");
        }
        handleClose();
      })
      .catch(async (err) => {
        toast.error("Error while adding task!");
      });
  };

  const editTask = (taskData) => {
    setPressed(false);
    setInitialValue(taskData);
    setEditFlow(true);
    setOpen(true);
  };

  const deleteTask = (taskData) => {
    axios
      .delete(`${API_URL}/rewards/delete/${taskData._id}`, config)
      .then((res) => {
        toast.success("Task deleted succesfully!");
        getTasksInfo();
      })
      .catch(() => {
        toast.error("Unable to delete the Task!");
      });
  };

  const classes = useStyles();
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Formik
              enableReinitialize
              validationSchema={addTaskValidation}
              initialValues={initialValue}
              onSubmit={(values) => {
                handleCreateTask(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                isValid,
                dirty,
              }) => (
                <>
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      style={{ marginBottom: 20 }}
                      variant="h3"
                      component="h2"
                    >
                      Add Task
                    </Typography>
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.title}
                      onChange={handleChange("title")}
                      label="Title"
                      type="text"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.reward}
                      onChange={handleChange("reward")}
                      label="Reward ($)"
                      type="number"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.goal}
                      onChange={handleChange("goal")}
                      label="Goal"
                      type="string"
                    />
                    <TextField
                      fullWidth
                      style={{ marginBottom: 14 }}
                      variant="outlined"
                      value={values.activeUsersRequired}
                      disabled={values.type === "daily"}
                      onChange={handleChange("activeUsersRequired")}
                      label="Number of Active Users required"
                      type="number"
                    />
                    {activeUsersRequiredError &&
                      values.activeUsersRequired === "" && (
                        <div>
                          <span fontSize={10} style={{ color: "red" }}>
                            Please enter Number of Active Users required
                          </span>
                        </div>
                      )}
                    <div id="d-flex flex-row p-3">
                      <span
                        style={{
                          fontSize: "1rem",
                          marginRight: "0.4rem",
                        }}
                      >
                        Choose Task type:
                      </span>
                      <span>
                        <label htmlFor="referral">
                          <input
                            type="radio"
                            id="referral"
                            name="referral"
                            value="referral"
                            checked={values.type === "referral"}
                            onChange={(e) => {
                              setFieldValue("type", e.target.value);
                            }}
                          />{" "}
                          Referral
                        </label>
                        <label htmlFor="daily">
                          <input
                            type="radio"
                            id="daily"
                            name="daily"
                            value="daily"
                            checked={values.type === "daily"}
                            onChange={(e) => {
                              setActiveUsersRequiredError(false);
                              setFieldValue("type", e.target.value);
                            }}
                          />{" "}
                          Daily
                        </label>
                      </span>
                    </div>
                    <div style={{ width: "100%", textAlign: "end" }}>
                      {!editFlow && (
                        <Button
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Save
                        </Button>
                      )}

                      {editFlow && (
                        <Button
                          disabled={pressed}
                          size="medium"
                          color="primary"
                          onClick={handleSubmit}
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        size="medium"
                        onClick={() => handleClose()}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </Modal>
        <PageTitle
          title="Referral Tasks"
          button={
            <>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={(e) => handleClick(e)}
              >
                <Add />
                Add Task
              </Button>
            </>
          }
        />
        <SearchBar
          style={{ marginBottom: 10 }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <Grid container spacing={4}>
          {actualData && (
            <Grid item xs={12}>
              <Widget
                numberOfEntries={tasksArr.length}
                title="Task List"
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
              >
                <Table className="mb-0">
                  <TableHead>
                    <TableRow>
                      <TableCell key="1">Title</TableCell>
                      <TableCell key="2" className={classes.longCell}>
                        Goal
                      </TableCell>
                      <TableCell key="3">Reward ($)</TableCell>
                      <TableCell key="4">Type</TableCell>
                      <TableCell key="5">Active Users required</TableCell>
                      <TableCell key="6">AddedAt</TableCell>
                      <TableCell key="7">Edit</TableCell>
                      <TableCell key="8">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasksArr.map(
                      ({
                        _id,
                        title,
                        goal,
                        reward,
                        type,
                        activeUsersRequired,
                        addedAt,
                      }) => (
                        <TableRow key={_id}>
                          <TableCell className="pl-3 fw-normal">
                            {title}
                          </TableCell>
                          <TableCell>{goal}</TableCell>
                          <TableCell>{reward}</TableCell>
                          <TableCell>{type}</TableCell>
                          <TableCell>
                            {type === "referral" ? activeUsersRequired : "-"}
                          </TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-in", {
                              dateStyle: "short",
                              timeStyle: "short",
                            }).format(new Date(addedAt))}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              editTask({
                                _id,
                                title,
                                reward,
                                goal,
                                activeUsersRequired,
                                addedAt,
                              });
                            }}
                          >
                            <Edit />
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              deleteTask({
                                _id,
                                title,
                                reward,
                                goal,
                                activeUsersRequired,
                                addedAt,
                              });
                            }}
                          >
                            <Delete />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Widget>
            </Grid>
          )}
        </Grid>
      </LoadingOverlay>
    </>
  );
};

export default Tasks;
