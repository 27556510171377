import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../context/constant";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError
) {
  try {
    setError(false);
    setIsLoading(true);
    const user = await axios.post(`${API_URL}/userMaster/login`, {
      emailId: login,
      password: password,
      type: "admin",
    });
    if (user.data.status === "OK") {
      localStorage.setItem("token", user.data.token);
      const res = await axios.post(`${API_URL}/userMaster/get`, {
        emailId: login,
      });
      localStorage.setItem("userData", JSON.stringify(res.data.data.data[0]));
    }
    if (!!login && !!password) {
      setTimeout(() => {
        localStorage.setItem("id_token", 1);
        setError(null);
        setIsLoading(false);
        dispatch({ type: "LOGIN_SUCCESS" });
        toast.success("Logged In successfully!");
        history.push("/app/dashboard");
      }, 2000);
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
